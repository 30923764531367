<template>
    <div class="infoCard">
		<div class="avatarBox">
			<img :src="avatar" class="avatar" alt="">
		</div>
		<div class="infoBox">
			<div class="personName">{{personName}}</div>
			<div class="personTag">
				<img src="@/assets/page4/Group4.png" class="tagOutline" alt=""> 
				{{personTag?personTag:'温柔大方'}}
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
.infoCard {
	height: 100%;
	position: relative;

	.avatarBox {
		height: 93.33%;
		aspect-ratio: 1/1;
		border-radius: 4px;
		overflow: hidden;
		position: absolute;
		z-index: 999;

		.avatar {
			width: 100%;
			height: 100%;
		}
	}

	.infoBox {
		position: absolute;
		height: 100%;
		width: 81.11%;
		right: 0;

		.personName {
			width: 100%;
			height: 2.04vh;
			font-size: 1.48vh;
			line-height: 2.04vh;
			color: #fff;
			font-family: 'pingfangM', sans-serif;
			text-align: center;
		}
		.personTag {
			position: relative;
			width: 100%;
			height: 2.96vh;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.3vh;
			color: #fff;
			font-family: 'pingfangSc', sans-serif;
			margin-top: 0.37vh;

			.tagOutline {
				position: absolute;
				width: 100%;
				height: 100%;
			}

		}

	}

}
</style>

<script>
export default {
	props:{
		personTag:{
			type: String
		},
		dataInput:{
			type: Object
		}
	},
	data(){
		return {
			personName: '测试员01',
			avatar: 'https://5b0988e595225.cdn.sohucs.com/images/20181108/b3efa733c5bb49bea87f9645421cf3c5.jpeg'
		}
	},
	mounted(){
		this.initData();
		return false;
	},
	watch:{
		dataInput(){
			this.initData();
			return false;
		}
	},
	methods: {
		initData(){
			let data = this.dataInput;
			this.personName = data.name || this.personName;
			//let picture = JSON.parse(data.picture);
			let picture = data.picture;
			this.avatar = picture.url || this.avatar;
		}
	}
}
</script>

